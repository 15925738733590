import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";

ReactDOM.render(
  <HashRouter>
    <Switch>
      {/* <Route path={`/auth`} component={AuthLayout} /> */}
      <Route path={`/admin`} component={AdminLayout} />
      {/* <Route path={`/rtl`} component={RTLLayout} /> */}
      <Redirect from={`/`} to="admin/dashboard" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

  class LineChart extends React.Component {
    
    constructor(props) {
      super(props);
  
      this.state = {
        chartData:  [
          {
            name: "Bookings",
            data: [8, 4, 22, 19, 39,10,13,24,12,18,16,12,14,9,5,14,2,18],
          },
        ],
        chartOptions: { 

          chart: {
          height: 10,
          type: 'line',
          toolbar: {
                  show: false,
                },
        },
        forecastDataPoints: {
          count: 6
        },
        stroke: {
          width: 4,
          curve: 'smooth'
        },
        tooltip: {
        theme: "dark",
        },
        dataLabels: {
        enabled: false,
        },
        labels: [
          ...this.getPreviousTenDays(),
          this.getCurrentDayFormatted(),
          ...this.getNextSixDays(),
        ],  
        xaxis: {
          // type: "datetime", // Set the type to category
              labels: {
                      style: {
                        colors: "#c8cfca",
                        fontSize: "12px",
                      },
                    },
        },
        title: {
          // text: 'Forecast',
          align: 'left',
          style: {
            fontSize: "16px",
            color: '#666'
          }
        },
        fill: {
        type: "gradient",
        gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
        },
        colors: ["#4FD1C5", "red"],
        },
        colors: ["#4FD1C5", "red"],
        annotations: {
        xaxis: [ {
         x: 11,
        x2: 17,
        // x2:"Aug 21",
        strokeDashArray: 0,      
        borderColor: '#ececec',
        label: {
        borderColor: '#FF814b',
        style: {
          color: '#000',
          background: '#FF814b',
        },
        text: 'Forcast Horizon',
        }
        }],
        
        },
        }
        ,
      };
    }
  
    componentDidMount() {
      const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();
    // Fetch results for current day and next seven days
    // for (let i = 0; i < 7; i++) {
      // const dayNumber = (currentDayNumber + i) % 7;
      // const season=this.getCurrentSeason()
      // this.fetchChartData(dayNumber,season);
    // }

  
    }
  
    fetchChartData = async (currentDay,season) => {
      
      try {
          axios.get("http://localhost:5000/api/ai/bookingdemand/predict", {
            params: {
              booking_week_day:currentDay,
              booking_is_day_weekend:0,
              booking_is_day_holiday:0,
              booking_season:season,
              model: "cat_boost_trainer",
            }
          })
          .then((response) => {
            const newResult = parseInt(response.data.result);
    
            // Append the new result to the existing data array
            const updatedChartData = this.state.chartData.map((dataItem) => ({
              ...dataItem,
              data: [...dataItem.data, newResult],
            }));
    
            this.setState({ chartData: updatedChartData });
          })
    
            .catch((error) => {
              console.error("Error fetching data:", error);
              this.setState({ error: "An error occurred while fetching data" });
            });
        
  
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    getCurrentSeason=()=> {
      const today = new Date();
      const month = today.getMonth();
    
      switch (month) {
        case 11:
        case 0:
        case 1:
          return 0; //winter
        case 2:
        case 3:
        case 4:
          return 1; //spring
        case 5:
        case 6:
        case 7:
          return 2; //summer
        case 8:
        case 9:
        case 10:
          return 3; //automn
        default:
          return -1;
      }
    }
    
    getCurrentDayFormatted=()=> {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'
      ];

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();

  const formattedDate = `${day} ${months[month]}`;
  return formattedDate;
}
 getFormattedDate=(date)=> {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const day = date.getDate();
  const month = date.getMonth();

  return `${day} ${months[month]}`;
}

 getPreviousTenDays=()=> {
  const today = new Date();
  const previousDays = [];

  for (let i = 10; i >= 1; i--) {
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() - i);
    previousDays.push(this.getFormattedDate(previousDate));
  }

  return previousDays;
}
 getNextSixDays=()=> {
  const today = new Date();
  const previousDays = [];

  for (let i = 1; i <= 7; i++) {
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() +i);
    previousDays.push(this.getFormattedDate(previousDate));
  }

  return previousDays;
}

    render() {
      const chartSeries = this.state.chartData.map((dataItem) => ({
        name: dataItem.name,
        data: dataItem.data.map((value, index) => [index, value]),
      }));
  
      return (
        <ReactApexChart
          options={this.state.chartOptions}
          series={chartSeries}
          type="area"
          width="100%"
          height="100%"
        />
      );
    }
  }
  

  
  export default LineChart;

  
  
  
  




// class LineChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       chartData: [],
//       chartOptions: {},
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       chartData: lineChartData,
//       chartOptions: lineChartOptions,
//     });
//   }

//   render() {
//     return (
//       <ReactApexChart
//         options={this.state.chartOptions}
//         series={this.state.chartData}
//         type="area"
//         width="100%"
//         height="100%"
//       />
//     );
//   }
// }

// export default LineChart;

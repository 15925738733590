// Chakra imports
import {
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// assets

import { Table, Tbody, Tr, Td } from '@chakra-ui/react';

import React, { useState } from "react";




export default function DataDoc() {
  const iconBoxInside = useColorModeValue("white", "white");

  const tableData = [
    ['Day/time', 'Total nb', '12AM-5AM', '5AM-8AM', '8AM-12PM', '12PM-16PM', '16PM-19PM', '19PM-12AM'],
    ['1', '[5-19]', '0-0', '2-5', '0-2', '0-2', '2-5', '2-5'],
    ['2', '[10,29]', '0-0', '2-5', '0-2', '0-2', '4-10', '4-10'],
    ['3', '[15,29]', '0-0', '2-5', '0-2', '0-2', '10', '10'],
    ['4', '[15,29]', '0-0', '2-5', '0-2', '0-2', '10', '10'],
    ['5', '[20,29]', '0-0', '2-5', '0-2', '0-2', '10', '10'],
    ['6', '[35,55]', '2-5', '4-10', '4-10', '4-10', '4-10', '4-10'],
    ['7', '[35,55]', '2-5', '4-10', '4-10', '4-10', '4-10', '4-10'],
    ['Holiday', '[35,55]', '2-5', '4-10', '4-10', '4-10', '4-10', '4-10'],
  ];

  const tableData2 = [
    ['Day/time', 'Total nb', '12AM-5AM', '5AM-8AM', '8AM-12PM', '12PM-16PM', '16PM-19PM', '19PM-12AM'],
    ['Holiday', '[35,55]', '[2-5]', '[4-10]', '[4-10]', '[4-10]', '[4-10]', '[4-10]'],
    ['1', '[5-19]', '[0-0]', '[2-5]', '[0-2]', '[0-2]', '[2-5]', '[2-5]'],
    ['2', '[10,29]', '[0-0]', '[2-5]', '[0-2]', '[0-2]', '[4-10]', '[4-10]'],
    ['3', '[15,29]', '[0-0]', '[2-5]', '[0-2]', '[0-2]', '[4-10]', '[4-10]'],
    ['4', '[15,29]', '[0-0]', '[2-5]', '[0-2]', '[0-2]', '[4-10]', '[4-10]'],
    ['5', '[20,29]', '[0-0]', '[2-5]', '[0-2]', '[0-2]', '[4-10]', '[4-10]'],
    ['6', '[35,55]', '[2-5]', '[4-10]', '[4-10]', '[4-10]', '[4-10]', '[4-10]'],
    ['7', '[35,55]', '[2-5]', '[4-10]', '[4-10]', '[4-10]', '[4-10]', '[4-10]'],

  ];

  const cellContent = [
    ['Season', 'Day', 'Chance', 'Temp interval'],
    ['Spring 01/03 31/05', 'Sunny', '35%', '[10,20]'],
    ['', 'Cloudy', '45%', '[5,15]',],
    ['', 'Rainy', '15%', '[5,15]',],
    ['', 'Snowy', '5%', '[-5,5]',],
    ['Summer 01/06 31/08', 'Sunny', '60%', '[20,30]'],
    ['', 'Cloudy', '25%', '[15,25]',],
    ['', 'Rainy', '10%', '[15,25]',],
    ['', 'Snowy', '5%', 'N/A',],
    ['Autumn 01/09 31/11', 'Sunny', '25%', '[10,20]'],
    ['', 'Cloudy', '40%', '[5,15]',],
    ['', 'Rainy', '25%', '[5,15]',],
    ['', 'Snowy', '10%', '[-5,5]',],
    ['Winter 01/12 29/02', 'Sunny', '10%', '[-5,5]'],
    ['', 'Cloudy', '30%', '[-5,5]',],
    ['', 'Rainy', '40%', '[-5,5]',],
    ['', 'Snowy', '20%', '[-10,0]',],
  ];
  const cellContentClients2 = [
    ['Age range', 'Prob%'],
    ['[18,21]', '[20,25]'],
    ['[21,25]', '[15,20]',],
    ['[25,30]', '[5,15]',],
    ['[30,40]', '[0,5]',],
    ['[40,60]', '[0,5]'],
  ];
  const cellContentCleint = [
    ['Gender', 'Age', 'Prob', 'Number'],
    ['Female 85% (850 clients)', '[18,21]', '5%', '42.5'],
    ['', '[21,25]', '15%', '127.5',],
    ['', '[25,30]', '20%', '170',],
    ['', '[30,40]', '40%', '340',],
    ['', '[40,60]', '20%', '170'],
    ['Male 15% (150 clients)', '[18,21]', '5%', '7.5',],
    ['', '[21,25]', '15%', '15',],
    ['', '[25,30]', '20%', '30',],
    ['', '[30,40]', '40%', '60',],
    ['', '[40,60]', '20%', '30'],
  ];
  const backgroundColorsClient = [
    '#fff', '#fff', '#fff', '#fff', '#fff', '#c4e8b2', '#c4e8b2', '#c4e8b2',
    "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
    "#f7caac", "#f7caac", "#f7caac", "#f7caac", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
    '#fff', '#c4e8b2', '#c4e8b2', '#c4e8b2', "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
    "#f7caac", "#f7caac", "#f7caac", "#f7caac", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
  ]
  const backgroundColorsClient2 = [
    '#fff', '#fff', '#c4e0b2', '#c4e0b2', '#c4e0b2', '#c4e0b2', "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
    "#f7caac", "#f7caac", "#f7caac", "#f7caac", "#ffe599", "#ffe599", "#ffe599", "#ffe599",
  ]

  const backgroundColors = [
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#ffe599',
    '#c4e0b2',
    '#ffe599',
    '#c4e0b2',
    '#c4e0b2',
    '#ffe599',
    '#ffe599',

    '#fff',
    '#ffe599',
    '#c4e0b2',
    '#ffe599',
    '#c4e0b2',
    '#c4e0b2',
    '#f7caac',
    '#f7caac',
    '#fff',
    '#ffe599',
    '#c4e0b2',
    '#ffe599',
    '#c4e0b2',
    '#c4e0b2',
    '#f7caac',
    '#f7caac',
    '#fff',
    '#ffe599',
    '#c4e0b2',
    '#ffe599',
    '#c4e0b2',
    '#c4e0b2',
    '#f7caac',
    '#f7caac',
    '#fff',
    '#ffe599',
    '#c4e0b2',
    '#ffe599',
    '#c4e0b2',
    '#c4e0b2',
    '#f7caac',
    '#f7caac',



    '#fff',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',

    '#fff',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',

    '#fff',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',
    '#f7caac',

  ];
  const backgroundColors2 = [
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',

    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',

    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',

    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',

    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#c4e0b2', //green
    '#ffe599',//yellow
    '#f7caac',//red
    '#c4e0b2',

    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#f7caac',//red
    '#f7caac',//red
    '#f7caac',//red
    '#c4e0b2',


    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#f7caac',//red
    '#f7caac',//red
    '#f7caac',//red
    '#c4e0b2',

  ];

  const [showInfosMobile, setShowInfosMobile] = useState(false)
  const [isActiveSection, setIsActiveSection] = useState("description")

  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    setIsActiveSection(section)
    console.log(element)
    setShowInfosMobile(true)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: 'smooth', block: "start",
        inline: 'nearest'
      });
    }
  };
  const numRows = 17;
  const numCols = 4;
  const numRowsClient = 11;
  const numColsClient = 4;
  const numRowsCleints2 = 6;
  const numColsClients2 = 2;
  const groupRows = 4; // Number of rows to group in the first column
  return (
    <Box pt={{ base: "120px", md: "75px" }} justifyContent={"space-between"} position={"relative"} width={"100%"}>
      <Box flex={0.7} width={"72%"} fontSize={"1rem"} background={"white"} borderRadius={"18px"} p={"20px"} boxShadow={"rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;"}>
        <Box id="description" onMouseEnter={() => setIsActiveSection("description")}>
          <Text fontWeight={"bold"} fontSize={"1.4rem"} marginBottom={"0.5rem"}>1. Description</Text>
          <Text pl={"1.1rem"}>
            We have a saloon booking platform where we have two actors:
            <ul style={{ paddingLeft: "1.5rem" }}>
              <li style={{ paddingTop: "0.5rem" }} >Saloon, or saloon owner: uses the app to manage his bookings by:
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>setting up his saloon (name, description, contact information,location, working hours)</li>
                  <li>creating services</li>
                  <li>adding staff members (name, photo, specialty)</li>
                  <li>Receive appointment confirmation requests (accept or decline)</li>
                  <li>list his appointments</li>
                  <li>list available slots</li>
                  <li>track traffic on his salon</li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>Clients: use a mobile app, or our website, to:
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>explore salons (by category, location, type, etc)</li>
                  <li>list available slots on a salon</li>
                  <li>make an appointment</li>
                  <li>manage appointments (cancel, book again, write a review)</li>
                  <li>manage the list of favorite salons</li>
                </ul>
              </li>
            </ul>

            In an attempt to powerup our solution we would like to implement an AI model for:
            <ul style={{ paddingLeft: "1.1rem" }}>
              <li>Predicting cancelations</li>
              <li>Predicting demand on appointment</li>
              <li>and maybe others in the future.</li>
            </ul>
          </Text>
        </Box>
        <Box id="features" onMouseEnter={() => setIsActiveSection("features")}>
          <Text fontWeight={"bold"} fontSize={"1.4rem"} marginBottom={"0.5rem"} marginTop={"0.8rem"}>2. Dataset features</Text>
          <Text pl={"1.1rem"}>
            To prepare our dataset, and start collecting, we decided that we can
            collect the following data:
            <ul style={{ paddingLeft: "1.5rem" }}>
              <li style={{ paddingTop: "0.5rem" }} >[when the appointment was made]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>date_created_appointment: Date where the appointment was
                    made (created)</li>
                  <li>time_created_appointment: Time where the appointment was
                    made (created)</li>
                  <li>day_created_appointment: a value in [1,7] to represent the day of
                    the week when the appointment was made</li>
                  <li>is_day_created_off: is the day when the appointment was made an
                    off day</li>
                  <li>is_day_created_holiday: is the day when the appointment was
                    made a holiday</li>
                  <li>is_day_created_sunny: is the day when the appointment was made
                    a sunny day</li>
                  <li>is_day_created_cloudy: is the day when the appointment was
                    made is a cloudy day</li>
                  <li>is_day_created_rainy: is the day when the appointment was made
                    is a rainy day</li>
                  <li>is_day_created_snowy: is the day when the appointment was
                    made is a snowy day</li>
                  <li>
                    day_created_temp: temperature of the day when the appointment
                    was made
                  </li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>[appointment day]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>date_of_appointment: date of the appointment</li>
                  <li>time_of_appointment: time of appointment</li>
                  <li>duration_of_appointment: the duration of the appointment</li>
                  <li>day_of_appointment: a value in [1,7] to represent the day of the
                    week of the appointment</li>
                  <li>is_day_of_holiday: is the appointment day a holiday</li>
                  <li>is_day_of_sunny: is the appointment day sunny</li>
                  <li>is_day_of_cloudy: is the appointment day cloudy</li>
                  <li>is_day_of_rainy: is the appointment day a rainy day</li>
                  <li>is_day_of_snowy: is the appointment day a snowy day</li>
                  <li>day_of_temp: temperature of the appointment day</li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>[appointment status]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>is_canceled: if the appointment was canceled by the client</li>
                  <li>is_declined: if the appointment was declined by the saloon</li>
                  <li>is_completed: if the client showed up</li>
                  <li>is_booked_again: did the client make another appointment after
                    this appointment</li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>[client]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>client_id: client id</li>
                  <li>client_age: client age</li>
                  <li>client_gender: client gender</li>
                  <li>client_service_rating: client rating of the service after completion (in
                    [1,5])</li>
                  <li>nb_canceled_appointments: the number of previously canceled
                    appointments for the client, to reflect client behavior and loyalty</li>
                  <li>nb_completed_appointments: the number of previously completed
                    appointments for the client, to reflect client behavior and loyalty</li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>[saloon]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>saloon_id: saloon id</li>
                  <li>saloon_category: saloon category</li>
                  <li>saloon_staff_id: staff id that worked with the client on the
                    appointment</li>
                  <li>saloon_service_id: picked service id</li>
                  <li>saloon_rating: saloon rating at the time when the appointment was
                    made (in [1,5])</li>
                  <li>saloon_city: saloon city</li>
                  <li>has_ongoing_compaign: does the saloon have any ongoing
                    campaigns the time the appointment was made</li>
                </ul>
              </li>
              <li style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>[client & saloon]
                <ul style={{ paddingLeft: "1.1rem" }}>
                  <li>is_bookmarked: did the client bookmark the saloon</li>
                </ul>
              </li>
            </ul>
          </Text>
        </Box>
        <Box id="building" onMouseEnter={() => setIsActiveSection("building")}>
          <Text fontWeight={"bold"} fontSize={"1.4rem"} marginBottom={"0.5rem"}>3. Building the dataset</Text>
          <Text pl={"1.1rem"}>
            In order to build our dataset, first we need to understand each variable in the dataset. Here, we classify the variables into five sets:
            <ul style={{ paddingLeft: "1.5rem", paddingBottom: "0.5rem" }}>
              <li>Booking</li>
              <li>Appointment</li>
              <li>Weather</li>
              <li>Client</li>
              <li>Saloon</li>
            </ul>

            Writing a script to generate a dataset requires understanding
            variables in all five sets, then defining all requirements and rules related to
            the set, which we will do in this section.
            <ul style={{ paddingLeft: "1.1rem" }}>
              <li>Booking <br /> This set of variables represents all features related to the moment
                when the appointment was made.
                <ul>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Feature set</Text>
                    Features for this set includes
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>date_created_appointment</li>
                      <li>
                        time_created_appointment
                      </li>
                      <li>day_created_appointment</li>
                      <li>is_day_created_off</li>
                      <li>is_day_created_holiday</li>
                    </ul>

                  </li>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>How it works?</Text>
                    Users use their phone based on two factors, the need to do something,
                    and free-time. Therefore, there will be a high probability for users to make
                    an appointment in their free time (e.g. weekday, holiday, off-working
                    hours).

                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Distribution</Text>
                    Each day, we can create a specific number of appointments, as a
                    function of date and time, to define how many appointments are created
                    (see next Figure ).
                    <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                      <Table size="sm" borderWidth="2px" borderColor="gray.500">
                        <Tbody>
                          {Array.from({ length: 9 }).map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                              {Array.from({ length: 8 }).map((_, colIndex) => (
                                <Td
                                  key={colIndex}
                                  bg={backgroundColors[rowIndex * 8 + colIndex]}
                                  borderWidth="1px"
                                  borderColor="gray.500"
                                  p="3"
                                  textAlign={"center"}
                                >
                                  {tableData[rowIndex][colIndex]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                        width={"fit-content"}>
                        Figure 1 Distribution of bookings over days of the week for one salon
                      </Text>
                    </Box>
                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Extra conditions</Text>
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>date_created_appointment must be after data_of_appointment</li>
                      <li>
                        date_created_appointment must be within last two years
                      </li>
                      <li style={{ color: "red" }}>number of bookings also increases in holidays</li>
                    </ul>

                  </li>
                </ul></li>

            </ul>

            <ul style={{ paddingLeft: "1.1rem" }}>
              <li><Text fontWeight={"bold"}>Appointment</Text>
                <ul>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Feature set</Text>
                    Features for this set includes
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>date_of_appointment</li>
                      <li>
                        time_of_appointment
                      </li>
                      <li>duration_of_appointment</li>
                      <li>day_of_appointment</li>
                      <li>is_canceled</li>
                      <li>is_declined</li>
                      <li>is_completed</li>
                      <li>is_booked_again</li>
                    </ul>

                  </li>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>How it works?</Text>
                    The time of the appointment must be within the working hours of the
                    saloon, as well as the appointment day. One factor that needs to be taken
                    into consideration for appointments is there is a high demand on
                    appointments on weekends and also on hours out-side the working hours.

                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Distribution</Text>
                    Each day, we can create a specific number of appointments, as a
                    function of date and time, to define how many appointments are created
                    (see next Figure ).
                    <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                      <Table size="sm" borderWidth="2px" borderColor="gray.500">
                        <Tbody>
                          {Array.from({ length: 9 }).map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                              {Array.from({ length: 8 }).map((_, colIndex) => (
                                <Td
                                  key={colIndex}
                                  bg={backgroundColors2[rowIndex * 8 + colIndex]}
                                  borderWidth="1px"
                                  borderColor="gray.500"
                                  p="3"
                                  textAlign={"center"}
                                >
                                  {tableData2[rowIndex][colIndex]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                        width={"fit-content"}>
                        Figure 2. Distribution of appointments over days of the week
                      </Text>
                    </Box>
                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Extra conditions</Text>
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>A saloon will have zero appointments on its off-day</li>
                      <li>
                        A saloon will have zero appointments on its off-hours
                      </li>
                      <li >At a specific day and time, a saloon may have one
                        appointment for each service+staff, no more</li>
                      <li>Only one of the features is_canceled, is_completed,
                        is_declined can be true</li>
                      <li>Is_booked_again</li>
                      <li>Appointment within 2 months tops from
                        date_created_appointment</li>
                    </ul>

                  </li>
                </ul></li>

            </ul>

            <ul style={{ paddingLeft: "1.1rem" }}>
              <li><Text fontWeight={"bold"}>Weather</Text>
                <ul>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Feature set</Text>
                    Features for this set includes
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>date_of_appointment</li>
                      <li>
                        time_of_appointment
                      </li>
                      <li>is_day_created_sunny</li>
                      <li>is_day_created_cloudy</li>
                      <li>is_day_created_rainy</li>
                      <li>is_day_created_snowy</li>
                      <li>day_created_temp</li>
                    </ul>

                  </li>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>How it works?</Text>
                    These features reflect the weather state the day when the booking
                    was created. Therefore, only one of four Boolean values can be true. The
                    weather state can be retrieved with a weather API on that date.
                    <br />
                    Also, we first must create the distribution for the weather and use it as
                    a reference for each booking, to avoid adding conflicts to our dataset.

                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Distribution</Text>
                    Each day, we can create a specific number of appointments, as a
                    function of date and time, to define how many appointments are created
                    (see next Figure ).
                    <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                      <Table size="sm" borderWidth="1px" borderColor="gray.300">
                        <Tbody>
                          {Array.from({ length: numRows }).map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                              {Array.from({ length: numCols }).map((_, colIndex) => (
                                colIndex == 0 && rowIndex % 4 != 1 && rowIndex != 0 ? null : (
                                  <Td
                                    key={colIndex}
                                    borderWidth="1px"
                                    borderColor="gray.300"
                                    p="3"
                                    align="center" // Center the content horizontally
                                    justify="center" // Center the content vertically
                                    rowSpan={colIndex === 0 && rowIndex % 4 === 1 ? 4 : 1}
                                    textAlign={"center"}
                                  >
                                    {cellContent[rowIndex][colIndex]}
                                  </Td>)
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>


                      <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                        width={"fit-content"}>
                        Figur 3 Distribution of weather status over the year
                      </Text>
                    </Box>
                  </li>

                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Extra conditions</Text>
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>Weather can be one of sunny, cloudy, rainy, or snowy, based on
                        the season</li>
                      <li>
                        The temperature is calculated as a function of date
                      </li>

                    </ul>

                  </li>
                </ul></li>

            </ul>


            <ul style={{ paddingLeft: "1.1rem" }}>
              <li><Text fontWeight={"bold"}>Client</Text>
                <ul>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Feature set</Text>
                    This represents the client profile, his behavior, how often does he make
                    appointments, how often he cancels, etc.<br />
                    Features for this set includes
                    <ul style={{ marginLeft: "1.2rem" }} >
                      <li>client_id: client id</li>
                      <li>
                        client_age: client age
                      </li>
                      <li>client_gender: client gender</li>
                      <li>client_service_rating: client rating of the service after completion (in
                        [1,5])</li>
                      <li>nb_canceled_appointments: the number of previously canceled
                        appointments for the client, to reflect client behavior and loyalty</li>
                      <li>nb_completed_appointments: the number of previously completed
                        appointments for the client, to reflect client behavior and loyalty</li>
                    </ul>

                  </li>
                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Other Variables</Text>
                    <ul>
                      <li>appointments</li>
                      <li>prob_cancel_appointment</li>
                      <li>prob_complete_appointment</li>
                    </ul>
                  </li>


                  <li style={{ marginLeft: "1.2rem" }}>
                    <Text fontWeight={"bold"}>Distribution</Text>
                    <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                      <Table size="sm" borderWidth="1px" borderColor="gray.300">
                        <Tbody>
                          {Array.from({ length: numRowsClient }).map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                              {Array.from({ length: numColsClient }).map((_, colIndex) => (
                                colIndex == 0 && rowIndex % 5 != 1 && rowIndex != 0 ? null : (
                                  <Td
                                    key={colIndex}
                                    borderWidth="1px"
                                    borderColor="gray.300"
                                    p="3"
                                    align="center" // Center the content horizontally
                                    justify="center" // Center the content vertically
                                    rowSpan={colIndex === 0 && rowIndex % 5 === 1 ? 5 : 1}
                                    textAlign={"center"}
                                    bg={backgroundColorsClient[rowIndex * 4 + colIndex]}
                                  >
                                    {cellContentCleint[rowIndex][colIndex]}
                                  </Td>)
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>


                      <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                        width={"fit-content"}>
                        Figure 4 Distribution of clients gender and age (case of 1000 clients)
                      </Text>
                    </Box>

                    <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                      <Table size="sm" borderWidth="1px" borderColor="gray.300">
                        <Tbody>
                          {Array.from({ length: numRowsCleints2 }).map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                              {Array.from({ length: numColsClients2 }).map((_, colIndex) => (

                                <Td
                                  key={colIndex}
                                  borderWidth="1px"
                                  borderColor="gray.300"
                                  p="3"
                                  align="center" // Center the content horizontally
                                  justify="center" // Center the content vertically
                                  textAlign={"center"}
                                  bg={backgroundColorsClient2[rowIndex * 4 + colIndex]}

                                >
                                  {cellContentClients2[rowIndex][colIndex]}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>


                      <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                        width={"fit-content"}>
                        Figure 5 Distribution of canceling an appointment for clients
                      </Text>
                    </Box>
                  </li>

                </ul></li>

            </ul>
          </Text>
        </Box>
      </Box>
      <Box flex={0.29} fontSize={"1.1rem"} background={"white"} borderRadius={"18px"} boxShadow={"rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;"}
        position={"fixed"} top={"105px"} width={"22%"} right={"15px"} p={"30px"}
      >
        On this page:
        <ul style={{ paddingLeft: "1.1rem", paddingTop: "12px" }}>

          <li onClick={() => handleClickScroll("description")} style={isActiveSection == "description" ? { fontWeight: "bold", cursor: "pointer" } : { cursor: "pointer" }} >Description</li>
          <li onClick={() => handleClickScroll("features")} style={isActiveSection == "features" ? { fontWeight: "bold", cursor: "pointer" } : { cursor: "pointer" }} >Data Features</li>
          <li onClick={() => handleClickScroll("building")} style={isActiveSection == "building" ? { fontWeight: "bold", cursor: "pointer" } : { cursor: "pointer" }} >Building The Dataset</li>
        </ul>
      </Box>
    </Box>
  );
}

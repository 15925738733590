// Chakra imports
import {
  Flex,
  Grid,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
// assets

import LineChart from "components/Charts/LineChart";

import React, { useState } from "react";

import PredictionsOverview from "./components/SalesOverview";


export default function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");
  const [filterTime,setFilterTime]=useState("day")
  
  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
     
     
      <Grid
        // templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(1, 2fr)", lg: "2fr" }}
        h={"90%"}
        gap='10px'
        mb={{ lg: "10px" }}>
     
          <Flex 
          w={"fit-content"}
          p={"3px"}
          background={"white"}
          borderRadius={"6px"}
          boxShadow={"rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;"}
          marginBottom={"0px"}
          >
            
            <Link href="#" onClick={(e)=>{e.preventDefault();setFilterTime("day")}} w={"100px"} textAlign={"center"} border={filterTime=="day"? "solid black 2px":""} borderRadius={"6px"}>Daglige</Link>
            <Link  href="#" onClick={(e)=>{e.preventDefault();setFilterTime("week")}} w={"100px"} textAlign={"center"} border={filterTime=="week"? "solid black 2px":""} borderRadius={"6px"}>Ugentlig</Link>
            <Link href="#" onClick={(e)=>{e.preventDefault();setFilterTime("month")}} w={"100px"} textAlign={"center"} border={filterTime=="month"? "solid black 2px":""} borderRadius={"6px"}>Månedlige</Link>
            <Link href="#" onClick={(e)=>{e.preventDefault();setFilterTime("year")}} w={"100px"} textAlign={"center"} border={filterTime=="year"? "solid black 2px":""} borderRadius={"6px"}>Årligt</Link>

          </Flex>
        <PredictionsOverview
          title={"Booking forudsigelser"}
          percentage={5}
          chart={<LineChart />}
        />
      </Grid>
      
    </Flex>
  );
}

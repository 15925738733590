// import
import Dashboard from "views/Dashboard/Dashboard";
import DataDoc from "views/DataDoc";
import ModelDoc from "views/ModelDoc";

import data from "./text.json"

import {FaRobot} from "react-icons/fa"
import {BiSolidFileDoc} from "react-icons/bi"
import {BsFillDatabaseFill} from "react-icons/bs"
var dashRoutes = [
  
  {
    path: "/dashboard",
    name: data.Dashboard,
    icon: <FaRobot color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/datadoc",
    name: "Dok for syntetiske data",
    icon: <BsFillDatabaseFill color="inherit" />,
    component: DataDoc,
    layout: "/admin",
  },
  {
    path: "/Modeldoc",
    name: "Dok for model",
    icon: <BiSolidFileDoc color="inherit" />,
    component: ModelDoc,
    layout: "/admin",
  },

];
export default dashRoutes;
